import React from 'react';

function AboutUs() {
    return (
        <div style={{ padding: '20px' }}>
            <h2>Sobre Nosotros</h2>
            <p>
                ¡Hola! En TRIFORCE SPORTS, somos más que un gimnasio, somos una comunidad dedicada a ayudarte a alcanzar tus metas de fitness.
                Con un ambiente motivador y entrenadores expertos, ofrecemos un espacio donde puedes desafiar tus límites y transformar tu vida.
            </p>
            <p>
                Ubicados en: <a href="https://maps.app.goo.gl/31VnHYCoJmp2YPYDA" target="_blank" rel="noopener noreferrer" style={{ color: 'var(--accent-color)', textDecoration: 'underline' }}>
                TRIFORCE SPORTS - Ver en Google Maps
            </a>
            </p>

            <div style={{marginTop: '30px'}}>
                <h3>Nuestra Ubicación en el Mapa</h3>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3587.610724321606!2d-105.36672298837401!3d25.948003677140377!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8696c1d7042b2931%3A0x92cbe48838bca6b9!2sTriforce%20Sports!5e0!3m2!1ses-419!2smx!4v1740286394443!5m2!1ses-419!2smx"
                    width="100%"
                    height="450"
                    style={{border: 0}}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
            {/* Puedes añadir más texto, imágenes, etc. debajo del mapa */}
        </div>
    );
}

export default AboutUs;