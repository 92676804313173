import React from 'react';
import Slider from 'react-slick';

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}
const photos = importAll(require.context('../assets/photos', false, /\.(png|jpe?g|svg|gif)$/));

function Home() {
    const settings = {
        dots: false,         // Ocultar puntos de navegación (opcional, si molestan en el fondo)
        infinite: true,
        speed: 1000,        // Velocidad de transición un poco más lenta
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000, // Autoplay un poco más lento
        arrows: false,
        fade: true,          // Transición de "fade" para las imágenes (opcional, puedes quitar si prefieres "slide")
    };

    return (
        <div style={{
            position: 'relative', /* Importante para posicionar el texto encima del carrusel */
            textAlign: 'center',
            height: '100vh',     /* Ajusta la altura al 100% del viewport para que el fondo carrusel cubra toda la pantalla Home */
            overflow: 'hidden',   /* Ocultar cualquier overflow del carrusel */
        }}>
            {/* Carrusel de fondo */}
            <div style={{
                position: 'absolute', /* Posiciona el carrusel como fondo absoluto */
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 0,         /* Asegura que el carrusel esté detrás del texto */
            }}>
                <Slider {...settings}>
                    {Object.keys(photos).map((key, index) => (
                        <div key={index}>
                            <div style={{
                                width: '100%',
                                height: '100vh', /* Ajusta la altura de cada slide al 100% del viewport */
                                backgroundImage: `url(${photos[key]})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                            }}></div>
                        </div>
                    ))}
                </Slider>
            </div>

            {/* Contenido de texto en primer plano */}
            <div id="home-hero-text" style={{ // Añadido ID para estilos específicos en CSS
                position: 'relative', /* Posiciona el texto relativamente dentro del contenedor Home */
                zIndex: 1,         /* Asegura que el texto esté delante del carrusel */
                padding: '100px 20px',
                color: 'var(--secondary-color)', /* Color amarillo para el texto */
                backgroundColor: 'rgba(0, 0, 0, 0.3)', /* Fondo semi-transparente para el texto (opcional, para mejorar legibilidad sobre las imágenes) */
                display: 'inline-block', /* Ajustar el fondo al tamaño del texto */
                borderRadius: '10px',    /* Bordes redondeados para el fondo del texto (opcional) */
            }}>
                <h1 style={{ fontSize: '5em', fontWeight: 'bolder', marginBottom: '0.1em', lineHeight: '0.7', color: 'var(--primary-color)' }}>SORPRENDE</h1>
                <h1 style={{ fontSize: '5em', fontWeight: 'bolder', marginBottom: '0.1em', lineHeight: '0.7', color: 'var(--primary-color)' }}>TU CUERPO</h1>
                <p style={{ fontSize: '1.3em', fontWeight: 'bold', color: 'var(--primary-color)' }}>TRIFORCE SPORTS - Transforma tu vida hoy.</p>
            </div>
        </div>
    );
}

export default Home;