import React from 'react';
import rutinasImage from '../assets/RUTINAS.png'; // Importa la imagen RUTINAS.png

function RutinasImage() {
    return (
        <div style={{ padding: '20px', textAlign: 'center' }}>
            <h2>Rutinas de Entrenamiento</h2>
            <p>Aquí puedes ver una vista previa de nuestras rutinas de entrenamiento.</p>
            <img
                src={rutinasImage}
                alt="Imagen de Rutinas de Entrenamiento"
                style={{ maxWidth: '100%', height: 'auto', display: 'block', margin: '20px auto' }}
            />
            {/* Opcional: Puedes añadir un enlace de descarga al PDF aquí también, si lo deseas */}
            {/*
      <p style={{ marginTop: '20px' }}>
        <a href="/assets/rutinas-triforce.pdf" download style={{ color: 'var(--secondary-color)', textDecoration: 'none', backgroundColor: 'var(--primary-color)', padding: '10px', borderRadius: '5px', border: '1px solid var(--secondary-color)', display: 'inline-block' }}>
          Descargar Rutinas PDF
        </a>
      </p>
      */}
        </div>
    );
}

export default RutinasImage;