import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo_black.svg';
import { slide as Menu } from 'react-burger-menu'; // Importa react-burger-menu

function Navbar() {
    const [menuOpen, setMenuOpen] = useState(false);

    const handleStateChange = (state) => {
        setMenuOpen(state.isOpen);
    }

    const closeMenu = () => {
        setMenuOpen(false);
    }

    return (
        <nav style={{ backgroundColor: 'var(--primary-color)', padding: '20px 0', color: 'var(--secondary-color)', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Link to="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', marginLeft: '20px' }}>
                <img src={logo} alt="TRIFORCE SPORTS Logo" style={{ height: '60px', marginRight: '15px' }} />
                <span style={{ color: 'var(--secondary-color)', fontWeight: 'bolder', fontSize: '1.4em' }}>TRIFORCE SPORTS</span>
            </Link>

            {/* Menú Hamburguesa con react-burger-menu */}
            <div className="bm-burger-menu-container" style={{ marginRight: '20px' }}>
                <Menu
                    isOpen={menuOpen}
                    onStateChange={(state) => handleStateChange(state)}
                    disableAutoFocus
                >
                    <Link onClick={() => closeMenu()} to="/" className="menu-item">INICIO</Link>
                    <Link onClick={() => closeMenu()} to="/nosotros" className="menu-item">NOSOTROS</Link>
                    <Link onClick={() => closeMenu()} to="/horarios" className="menu-item">HORARIOS</Link>
                    <Link onClick={() => closeMenu()} to="/rutinas" className="menu-item">RUTINAS</Link>
                </Menu>
            </div>

            {/* Menu horizontal para desktop (se oculta en moviles con CSS) */}
            <ul className="nav-links" className="nav-links" style={{ listStyle: 'none', padding: 0, margin: 0, gap: '40px' }}> {/* ¡¡¡ELIMINASTE display: 'flex' DEL style INLINE!!! */}
                <li><Link to="/" style={{ color: 'var(--secondary-color)', textDecoration: 'none', fontWeight: 'bold' }}>INICIO</Link></li>
                <li><Link to="/nosotros" style={{ color: 'var(--secondary-color)', textDecoration: 'none', fontWeight: 'bold' }}>NOSOTROS</Link></li>
                <li><Link to="/horarios" style={{ color: 'var(--secondary-color)', textDecoration: 'none', fontWeight: 'bold' }}>HORARIOS</Link></li>
                <li><Link to="/rutinas" style={{ color: 'var(--secondary-color)', textDecoration: 'none', fontWeight: 'bold' }}>RUTINAS</Link></li>
            </ul>
        </nav>
    );
}

export default Navbar;