import React from 'react';

function Horarios() {
    return (
        <div style={{ padding: '20px' }}>
            <h2>Horarios de Clase</h2>
            <p>Consulta nuestros horarios de clase semanales. ¡Tenemos opciones para todos los niveles y horarios!</p>

            {/* Aquí puedes añadir tablas, listas u otros elementos para mostrar los horarios */}
            <ul>
                <li>Lunes a Viernes: 7:00 AM - 10:00 PM</li>
                <li>Sábados: 9:00 AM - 6:00 PM</li>
                <li>Domingos: Cerrado</li>
            </ul>

            {/* Ejemplo de tabla básica para horarios (puedes estilizar con CSS) */}
            <h3>Horario Detallado</h3>
            <table>
                <thead>
                <tr>
                    <th>Día</th>
                    <th>Mañana</th>
                    <th>Tarde</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Lunes</td>
                    <td>7:00 AM - 12:00 PM</td>
                    <td>4:00 PM - 10:00 PM</td>
                </tr>
                <tr>
                    <td>Martes</td>
                    <td>7:00 AM - 12:00 PM</td>
                    <td>4:00 PM - 10:00 PM</td>
                </tr>
                {/* ... más filas para otros días ... */}
                </tbody>
            </table>

            <p>¡Te esperamos!</p>
        </div>
    );
}

export default Horarios;