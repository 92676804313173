import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import Horarios from './components/Horarios';
import RutinasImage from './components/RutinasImage'; // Importa el nuevo componente RutinasImage

function App() {
    return (
        <Router>
            <div style={{ backgroundColor: 'black', color: 'yellow', minHeight: '100vh' }}>
                <Navbar />
                <div style={{ padding: '20px' }}> {/* Espacio general alrededor del contenido principal */}
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/nosotros" element={<AboutUs />} />
                        <Route path="/horarios" element={<Horarios />} />
                        {/* Ruta /rutinas para mostrar la imagen RutinasImage */}
                        <Route path="/rutinas" element={<RutinasImage />} />
                    </Routes>
                </div>
                <footer style={{ textAlign: 'center', padding: '10px', borderTop: '1px solid yellow' }}>
                    <p>© {new Date().getFullYear()} TRIFORCE SPORTS - Todos los derechos reservados.</p>
                </footer>
            </div>
        </Router>
    );
}

export default App;